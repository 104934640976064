import CoomingSoon from "./components/CoomingSoon";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App () {

  return (
    <div className="App">

      <CoomingSoon />
      <ToastContainer />
    </div>
  );
}

export default App;
