import React, { useEffect, useState } from "react";
import "../countDownStyle.css";

function Countdown({ endDate, endTime }) {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date(`${endDate}T${endTime}`);
    const updateCountdown = () => {
      const currentDate = new Date();
      const timeDifference = targetDate - currentDate;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [endDate, endTime]);

  return (
    // <div className="count-down">
    //   <p>
    //     <span>Days</span>
    //     <span className="firstSpan">{timeRemaining.days}</span>
    //   </p>
    //   <p>
    //     <span>Hours</span>{" "}
    //     <span className="firstSpan">{timeRemaining.hours}</span>
    //   </p>
    //   <p>
    //     <span>Mins</span>
    //     <span className="firstSpan">{timeRemaining.minutes}</span>
    //   </p>
    //   <p>
    //     <span>Sec</span>
    //     <span className="firstSpan">{timeRemaining.seconds}</span>
    //   </p>
    <div className="time-content">
      <div className="time days">
        <span className="number">{timeRemaining.days}</span>
        <span className="text">days</span>
      </div>
      <div className="time hours">
        <span className="number">{timeRemaining.hours}</span>
        <span className="text">hours</span>
      </div>
      <div className="time minutes">
        <span className="number">{timeRemaining.minutes}</span>
        <span className="text">minutes</span>
      </div>
      <div className="time seconds">
        <span className="number">{timeRemaining.seconds}</span>
        <span className="text">seconds</span>
      </div>
    </div>
    // </div>
  );
}

export default Countdown;
