import { FormProvider, useForm } from "react-hook-form";
import Countdown from "./CountDown";
import axios from "axios";
import { toast } from "react-toastify";

const emailValidation = {
  required: {
    value: true,
    message: "Email Is Required",
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Not Valid Email",
  },
};

const CoomingSoon = () => {
  const methods = useForm();
  const { register, formState } = methods;
  const inputErrors = formState.errors["email"] || [];

  const submitHandler = methods.handleSubmit(async (data) => {
    // try {
    //   await axios.post("http://localhost:5000/sub-user", {
    //     userEmail: data.email,
    //   });
    //   toast.success(
    //     "Congratulations! 🎉 You've successfully subscribed to our join list."
    //   );
    //   methods.reset();
    // } catch (error) {
    //   toast.error(
    //     "Oops! It seems there was a problem subscribing you to our join list. Please try again later."
    //   );
    // }
  });
  const endDate = "2024-08-15";
  const endTime = "00:00:00";

  return (
    <>
      <section className="container">
        <header>Launching Soon: Your Gateway to Exclusive Auctions!</header>
        <p>
          Launching Soon: Your Gateway to Exclusive Auctions! Our platform is
          set to unveil an unparalleled experience, offering you access to a
          world of unique auctions. Immerse yourself in a curated selection of
          rare finds, collectibles, and coveted items. Stay tuned as we prepare
          to bring you thrilling auctions like never before. Prepare to bid,
          win, and discover your next prized possession! Join us as we redefine
          the art of online auctions.
        </p>
        <Countdown endDate={endDate} endTime={endTime} />
        <div className="email-content">
          <FormProvider {...methods} className="email-content">
            <form className="input-box" onSubmit={(e) => e.preventDefault()}>
              <input
                className={`input ${inputErrors && "inputErrors"}`}
                type={"email"}
                id={"email"}
                name={"email"}
                placeholder={`Enter Email`}
                {...register("email", emailValidation)}
              />

              <button onClick={submitHandler}>Join waitlist</button>
            </form>
          </FormProvider>
        </div>
      </section>
    </>
  );
};

export default CoomingSoon;
